import {
  AUTH_CURRENT_USER,
  AUTH_REMOVE_USER,
  ORDERS_APPROVALS_STATUS,
  AUTH_UPDATE_COMPANY_BILLING_ADDRESS,
} from '../lib/types';

const initialState = {
  user: null,
  permissions: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_CURRENT_USER:
      return {
        ...state,
        user: action.payload.user,
        permissions: action.payload.permissions,
      };
    case AUTH_REMOVE_USER:
      localStorage.removeItem('am-token');
      return {
        ...state,
        user: null,
      };
    case ORDERS_APPROVALS_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          company: { ...state.user.company, ...action.payload },
        },
      };
    case AUTH_UPDATE_COMPANY_BILLING_ADDRESS:
      return {
        ...state,
        user: {
          ...state.user,
          company: {
            ...state.user.company,
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default authReducer;
