import DocumentsSerializer from 'lib/serializers/Documents';
import { ORDER_DOCUMENTS_FETCH } from 'lib/types';

const initialState = {
  table: new DocumentsSerializer(null),
  preload: true,
};

function orderDocumentsReducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_DOCUMENTS_FETCH:
      return {
        ...state,
        table: action.payload,
      };
    default:
      return state;
  }
}

export default orderDocumentsReducer;
