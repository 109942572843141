import {
  SITES_FETCH_SITES_FOR_USER,
  SITES_FETCH_GROUPS_FOR_SITE,
  SITES_SET_DETAIL_SITE,
  SITES_RESET_FETCH_SITES,
} from '../lib/types';
import SitesSerializer from '../lib/serializers/SitesSerializer';
import SiteSerializer from '../lib/serializers/SiteSerializer';

const initialState = {
  sites: new SitesSerializer(null),
  siteDetail: new SiteSerializer(null),
};

const sitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SITES_FETCH_SITES_FOR_USER:
      return {
        ...state,
        sites: action.payload,
      };
    case SITES_SET_DETAIL_SITE: {
      const site = state.sites.results.filter(
        (s) => s.name.toLowerCase() === action.payload.toLowerCase(),
      )[0];
      return {
        ...state,
        siteDetail: site,
      };
    }
    case SITES_RESET_FETCH_SITES:
      return {
        ...state,
        sites: new SitesSerializer(null),
      };
    case SITES_FETCH_GROUPS_FOR_SITE: {
      const { index, groups } = action.payload;
      state.sites.results[index].groups = groups;
      const results = state.sites.results.map((v) => v);

      return {
        ...state,
        sites: { ...state.sites, results },
      };
    }
    default:
      return state;
  }
};

export default sitesReducer;
