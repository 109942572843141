import { combineReducers } from 'redux';

import ordersReducer from './orders.reducer';
import alertsReducer from './alerts.reducer';
import globalsReducer from './globals.reducer';
import authReducer from './auth.reducer';
import sitesReducer from './sites.reducer';
import usersReducer from './users.reducer';
import rolesReducer from './roles';
import siteTypesReducer from './site-types';
import hierarchyReducer from './hierarchy';
import approvalRulesReducer from './approval-rules';
import documentsReducer from './documents';
import orderDocumentsReducer from './order-documents';
import mccAdminReducer from './admin';
import warehousesReducer from './warehouses.reducer';

export default combineReducers({
  globals: globalsReducer,
  auth: authReducer,
  orders: ordersReducer,
  alerts: alertsReducer,
  sites: sitesReducer,
  users: usersReducer,
  roles: rolesReducer,
  siteTypes: siteTypesReducer,
  hierarchy: hierarchyReducer,
  approvalRules: approvalRulesReducer,
  documents: documentsReducer,
  orderDocuments: orderDocumentsReducer,
  mccAdmin: mccAdminReducer,
  warehouses: warehousesReducer,
});
