import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './lib/store';
import themeServiceAPI from './lib/api-theme-service';
import { setThemeFromApi } from './actions/globals.actions';
import { Helmet } from 'react-helmet';

const { dispatch, getState } = store;
new Promise(async (resolve, reject) => {
  try {
    const { data } = await themeServiceAPI.get(`urls/${getState().globals.company.themeId}`);
    resolve(data);
  } catch (error) {
    reject(error);
  }
}).then((theme) => {
  dispatch(setThemeFromApi(theme));
  ReactDOM.render(
    <Provider store={store}>
      <Helmet>
        <link rel="stylesheet" href={getState().globals.company.themeUrl} />
      </Helmet>
      <App />
    </Provider>,
    document.getElementById('root'),
  );
}).catch((error) => {
  console.error('Error fetching theme...', error);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
