import { toast } from 'react-toastify';

import TreeSerializer from 'lib/serializers/HierarchyTreeSerializer';

import siteManagerAPI from '../../../lib/api-manager';
import { API_SITES, API_USERS, API_HIERARCHY_TREE } from '../../../lib/api-endpoints';
import { UNLIMITED_LIST_LIMIT } from '../../../constants/values';
import {
  FETCH_SITES_ERROR,
  FETCH_POLICIES_ERROR,
  FETCH_TREE_ERROR,
} from '../../../constants/error-messages';

import * as utils from './utils';

export async function fetchSites() {
  try {
    const response = await siteManagerAPI.get(
      `${API_SITES}?liteVersion=true&limit=${UNLIMITED_LIST_LIMIT}`,
    );
    return utils.formatPoliciesSites(response.data.results);
  } catch (error) {
    toast.error(FETCH_SITES_ERROR);
    return [];
  }
}

export async function fetchUsersWithoutPolicies() {
  try {
    const response = await siteManagerAPI.get(
      `${API_USERS}?hasPolicies=false&liteVersion=true&limit=${UNLIMITED_LIST_LIMIT}`,
    );
    return utils.formatUsers(response.data.results);
  } catch (error) {
    toast.error(FETCH_SITES_ERROR);
    return [];
  }
}

export async function fetchUserPolicyData(userId) {
  try {
    const response = await siteManagerAPI.get(`${API_USERS}${userId}/?withSites=true`);
    return {
      user: utils.formatUser(response.data),
      sites: utils.formatPoliciesSites(response.data.sites),
    };
  } catch (error) {
    toast.error(FETCH_POLICIES_ERROR);
    return [];
  }
}

export async function fetchTree() {
  try {
    const response = await siteManagerAPI.get(API_HIERARCHY_TREE);
    return TreeSerializer(response.data);
  } catch {
    toast.error(FETCH_TREE_ERROR);
  }
}
