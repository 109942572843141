import {
  APPROVAL_RULES_SAVE_CURRENT,
  APPROVAL_RULES_SAVE_LIST,
  APPROVAL_RULES_SAVE_ERRORS,
} from 'lib/types';

const initialState = {
  current: null,
  errors: null,
  list: {
    data: [],
    pagination: {},
  },
};

function approvalRulesReducer(state = initialState, action) {
  switch (action.type) {
    case APPROVAL_RULES_SAVE_CURRENT:
      return {
        ...state,
        current: {
          ...(state.current || {}),
          ...action.payload,
        },
      };
    case APPROVAL_RULES_SAVE_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case APPROVAL_RULES_SAVE_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
}

export default approvalRulesReducer;
