import goExpediLogo from 'assets/goexpedi-logo.svg';
import goExpediLogoAlt from 'assets/goexpedi-logo-white.png';
import {
  GLOBALS_IS_SIDEBAR_OPEN,
  GLOBALS_SHOULD_DISPLAY_SPINNER,
  GLOBALS_SHOULD_DISPLAY_SPLASH_SCREEN,
  GLOBALS_SET_MODAL_DATA,
  GLOBALS_CURRENT_COMPANY,
  GLOBALS_SET_EXCEL_HEADERS,
  GLOBALS_RESET,
  GLOBALS_SET_IS_TABLE_LOADING,
  GLOBALS_SET_SIDEBAR_STATE,
  GLOBALS_SET_THEME_DATA,
} from '../lib/types';

//example: "-special-domain": "specialdomain"
const SPECIAL_DOMAIN_NAMES = {
}

const themeMatch = window.location.host.match(/go(.*)\..*/);
let themeId = Array.isArray(themeMatch) && themeMatch[1];

if (themeId && themeId.includes('-')) {
  themeId = themeId in SPECIAL_DOMAIN_NAMES ? SPECIAL_DOMAIN_NAMES[themeId] : themeId.slice(1)
};

const company = {
  hasOrderApproval: false,
  themeId: themeId || 'GoExpedi',
  logo: goExpediLogo,
  logoLogin: goExpediLogo,
  logoSidebar: goExpediLogoAlt,
  logoSplash: goExpediLogoAlt,
  label: 'GoExpedi',
  shortName: 'Expedi',
  shopUrl: 'https://shop.goexpedi.com/'
};

let isSbOpen;
const sbStorage = localStorage.getItem('sb-open');
if (sbStorage) {
  isSbOpen = sbStorage === 'true';
} else {
  isSbOpen = true;
}

const initialState = {
  debug: false,
  isSideBarOpen: isSbOpen,
  shouldDisplaySpinner: false,
  shouldDisplaySplashScreen: true,
  company,
  modal: {
    size: 'modal-md',
    title: '',
    jsx: [],
    components: [],
    buttons: [],
  },
  excelHeaders: [],
  isTableLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GLOBALS_RESET:
      return { ...initialState, ...action.payload, shouldDisplaySplashScreen: false };
    case GLOBALS_IS_SIDEBAR_OPEN: {
      const isOpen = !state.isSideBarOpen;
      localStorage.setItem('sb-open', `${isOpen}`);
      return {
        ...state,
        isSideBarOpen: isOpen,
      };
    }
    case GLOBALS_SET_SIDEBAR_STATE: {
      localStorage.setItem('sb-open', `${action.payload}`);
      return {
        ...state,
        isSideBarOpen: action.payload,
      };
    }
    case GLOBALS_SHOULD_DISPLAY_SPLASH_SCREEN:
      return {
        ...state,
        shouldDisplaySplashScreen: action.payload,
      };
    case GLOBALS_SHOULD_DISPLAY_SPINNER:
      return {
        ...state,
        shouldDisplaySpinner: action.payload,
      };
    case GLOBALS_SET_MODAL_DATA: {
      const modal = action.payload.component;
      const actions = {};

      if (action.payload.onClose) {
        actions.onClose = action.payload.onClose;
      }

      if (action.payload.onSubmit) {
        actions.onSubmit = action.payload.onSubmit;
      }

      return {
        ...state,
        modal,
        ...actions,
      };
    }
    case GLOBALS_CURRENT_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case GLOBALS_SET_EXCEL_HEADERS:
      return {
        ...state,
        excelHeaders: [...action.payload].filter((e) => e.name !== 'actions'),
      };
    case GLOBALS_SET_IS_TABLE_LOADING:
      return {
        ...state,
        isTableLoading: action.payload,
      };
    case GLOBALS_SET_THEME_DATA:
      return {
        ...state,
        company: {
          ...state.company,
          ...action.payload
        },
      };
    default:
      return state;
  }
};
