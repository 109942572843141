import {
  USERS_FETCH_USERS_FOR_COMPANY,
  USERS_SET_USER_FOR_USER_DETAIL_PAGE,
  USERS_RESET_FETCH_USERS,
} from '../lib/types';
import UsersSerializer from '../lib/serializers/UsersSerializer';
import UserSerializer from '../lib/serializers/UserSerializer';

const initialState = {
  users: new UsersSerializer(null),
  userDetail: new UserSerializer(null),
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_FETCH_USERS_FOR_COMPANY:
      return {
        ...state,
        users: action.payload,
      };
    case USERS_SET_USER_FOR_USER_DETAIL_PAGE:
      return {
        ...state,
        userDetail: new UserSerializer(action.payload),
      };
    case USERS_RESET_FETCH_USERS:
      return {
        ...state,
        users: new UsersSerializer(null),
      };
    default:
      return state;
  }
};

export default usersReducer;
