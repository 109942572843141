import {
  ROLES_FETCH_ROLE,
  ROLES_FETCH_COMPANY_ROLES,
  ROLES_SET_ROLE_FORM_ERRORS,
} from '../lib/types';

import RolesSerializer from '../lib/serializers/RolesSerializers';
import RoleSerializer from '../lib/serializers/RoleSerializer';

const initialState = {
  role: new RoleSerializer(null),
  roles: new RolesSerializer(null),
  errors: {},
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROLES_FETCH_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case ROLES_FETCH_COMPANY_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case ROLES_SET_ROLE_FORM_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default rolesReducer;
