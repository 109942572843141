import {
  HIERARCHY_SAVE_NODES,
  HIERARCHY_SAVE_GROUPS,
  HIERARCHY_SAVE_USERS,
  HIERARCHY_SAVE_SITES,
  HIERARCHY_SAVE_TABLE_NODES,
  HIERARCHY_SAVE_CURRENT_NODE,
  HIERARCHY_SAVE_CURRENT_GROUP,
  HIERARCHY_FETCH_GROUPS,
  HIERARCHY_SAVE_TABLE_POLICIES,
  HIERARCHY_UPDATE_CURRENT_NODE,
  HIERARCHY_FETCH_TREE,
} from '../lib/types';

const initialState = {
  currentNode: null,
  nodes: [],
  groups: {},
  sites: [],
  users: [],
  table: {
    nodes: [],
    pagination: {},
  },
  policiesTable: {
    policies: [],
    pagination: {},
  },
};

function hierarchyReducer(state = initialState, action) {
  switch (action.type) {
    case HIERARCHY_FETCH_GROUPS:
      return {
        ...state,
        visibleGroups: action.payload.visibleGroups,
      };
    case HIERARCHY_SAVE_CURRENT_NODE:
      return {
        ...state,
        currentNode: action.payload.node,
      };
    case HIERARCHY_UPDATE_CURRENT_NODE:
      return {
        ...state,
        currentNode: {
          ...(state.currentNode || {}),
          ...action.payload,
        },
      };
    case HIERARCHY_SAVE_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: action.payload.currentGroup,
      };
    case HIERARCHY_SAVE_NODES:
      return {
        ...state,
        nodes: action.payload.nodes,
      };
    case HIERARCHY_SAVE_GROUPS:
      return {
        ...state,
        groups: action.payload.groups,
      };
    case HIERARCHY_SAVE_USERS:
      return {
        ...state,
        users: action.payload.users,
      };
    case HIERARCHY_SAVE_SITES:
      return {
        ...state,
        sites: action.payload.sites,
      };
    case HIERARCHY_SAVE_TABLE_NODES:
      return {
        ...state,
        table: action.payload.table,
      };
    case HIERARCHY_SAVE_TABLE_POLICIES:
      return {
        ...state,
        policiesTable: action.payload.table,
      };
    case HIERARCHY_FETCH_TREE:
      return {
        ...state,
        tree: action.payload,
      };
    default:
      return state;
  }
}

export default hierarchyReducer;
