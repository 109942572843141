/* eslint-disable no-param-reassign */
/* For IE 11, this polyfill is needed to support unicode regex patterns */

const { RegExp } = window;
try {
  // eslint-disable-next-line no-new
  new RegExp('a', 'u');
} catch (err) {
  window.RegExp = (pattern, flags) => {
    if (flags?.includes('u') === true) {
      // Ignore unicode flag in RegExp
      flags = flags === 'u' ? undefined : flags.replace('u', '');
      // Discard parts of the patterns used by exceljs that error out in non-unicode RegExps.
      pattern = pattern.replace(/\uDC00-\uDBFF/g, '');
      pattern = pattern.replace(/\uDC00-\uDB7F/g, '');

      return new RegExp(pattern, flags);
    }
    return new RegExp(pattern, flags);
  };
  window.RegExp.prototype = RegExp;
}
