import DocumentsSerializer from 'lib/serializers/Documents';
import { DOCUMENTS_FETCH, DOCUMENTS_PRELOADED } from 'lib/types';

const initialState = {
  table: new DocumentsSerializer(null),
  preload: true,
};

function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case DOCUMENTS_FETCH:
      return {
        ...state,
        table: action.payload,
      };
    case DOCUMENTS_PRELOADED:
      return {
        ...state,
        preload: action.payload,
      };
    default:
      return state;
  }
}

export default documentsReducer;
