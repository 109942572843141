import CompaniesSerializer from 'lib/serializers/mcc-admin/CompaniesSerializer';
import { ADMIN_COMPANIES_FETCH, ADMIN_SET_CURRENT_COMPANY } from 'lib/types';

const initialState = {
  table: new CompaniesSerializer(null),
  currentCompany: null,
};

function adminReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_COMPANIES_FETCH:
      return {
        ...state,
        table: action.payload,
      };
    case ADMIN_SET_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.payload,
      };
    default:
      return state;
  }
}

export default adminReducer;
