import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import combinedReducers from '../reducers/combine-reducers';

const initialState = {};
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(combinedReducers, initialState, composeEnhancer(applyMiddleware(thunk)));

export default store;
