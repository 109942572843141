import axios from 'axios';

import {
  THEME_SERVICE_BE_HOST,
  THEME_SERVICE_BE_TOKEN,
} from '../utils/runtime-config';

export default axios.create({
  baseURL: THEME_SERVICE_BE_HOST,
  headers: {
    Authorization: `Bearer ${THEME_SERVICE_BE_TOKEN}`,
  },
});
