import { SITES_FETCH_WAREHOUSE_LIST } from '../lib/types';

const initialState = {
  names: null,
};

const warehousesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SITES_FETCH_WAREHOUSE_LIST:
      return {
        ...state,
        names: action.payload,
      };
    default:
      return state;
  }
};

export default warehousesReducer;
