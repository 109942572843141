import {
  SITE_TYPES_FETCH_SITE_TYPE,
  SITE_TYPES_FETCH_COMPANY_SITE_TYPES,
  SITE_TYPES_SET_SITE_TYPE_FORM_ERRORS,
} from '../lib/types';
import SiteTypesSerializer from '../lib/serializers/SiteTypesSerializer';
import SiteTypeSerializer from '../lib/serializers/SiteTypeSerializer';

const initialState = {
  siteType: new SiteTypeSerializer(null),
  siteTypes: new SiteTypesSerializer(null),
  errors: {},
};

const siteTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SITE_TYPES_FETCH_SITE_TYPE:
      return {
        ...state,
        siteType: action.payload,
      };
    case SITE_TYPES_FETCH_COMPANY_SITE_TYPES:
      return {
        ...state,
        siteTypes: action.payload,
      };
    case SITE_TYPES_SET_SITE_TYPE_FORM_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default siteTypesReducer;
